<adxad-loader
  *ngIf="isLoading || isLoadingStatus"
  [diameter]="40"
  adxadSidebarModalLoader
></adxad-loader>

<adxad-sidebar-modal-container
  *ngIf="ssp && !isLoading"
  class="creative-view"
>
  <adxad-sidebar-modal-header>
    <adxad-sidebar-modal-close-btn (click)="close()"></adxad-sidebar-modal-close-btn>
    <h1 adxadSidebarModalTitle>{{ ssp.name }}</h1>
  </adxad-sidebar-modal-header>

  <adxad-sidebar-modal-body>
    <section
      [hideSeparator]="true"
      adxadSidebarModalSection
      class="row"
    >
      <adxad-sidebar-modal-info-block
        class="col-12"
        label="Api key"
      >
        <adxad-public-api-key
          [apiKey]="ssp.apiKey"
          [id]="ssp.id"
          type="ssp"
        />
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="External ID"
      >
        <div class="ext-id">
          {{ ssp.extId }}

          <span
            class="material-icons --icon"
            adxadTooltip="Click to copy"
            (click)="copyValue(ssp.extId)"
          >
            filter_none
          </span>
        </div>
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Status"
      >
        <adxad-entity-status [entityStatus]="ssp.status"></adxad-entity-status>
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Created at"
      >
        {{ ssp.createdAt | date: 'MM/dd/y H:mm:ss' }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Updated at"
      >
        {{ ssp.updatedAt | date: 'MM/dd/y H:mm:ss' }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Cost model"
      >
        {{ ssp.costModel }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Segment"
      >
        {{ ssp.segment }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Open RTB"
      >
        <span [ngClass]="ssp.isOpenRtb ? 'success' : 'error'"> {{ ssp.isOpenRtb ? 'Enabled' : 'Disabled' }} </span>
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Response type"
      >
        {{ ssp.responseType }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Test mode"
      >
        <span [ngClass]="ssp.testMode ? 'success' : 'error'"> {{ ssp.testMode ? 'Enabled' : 'Disabled' }} </span>
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Access token"
      >
        {{ ssp.accessToken }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="Default revshare (%)"
      >
        {{ ssp.commission }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        *ngIf="ssp.responseTemplate"
        class="col-12"
        label="Response template"
      >
        {{ ssp.responseTemplate }}
      </adxad-sidebar-modal-info-block>
    </section>

    <adxad-tabs-nav *ngIf="ssp.filters?.length || ssp.geoRevshare?.length">
      <adxad-tab-link
        (click)="openTab(1)"
        [class.active]="showTab === 1"
        *ngIf="ssp.filters?.length"
      >
        Filters
      </adxad-tab-link>
      <adxad-tab-link
        (click)="openTab(2)"
        *ngIf="ssp.geoRevshare?.length"
        [class.active]="showTab === 2"
      >
        Geo revshare
      </adxad-tab-link>
    </adxad-tabs-nav>
    <!--  Filters  -->
    <ng-container *ngIf="showTab === 1">
      <div class="filters-list">
        <div
          *ngFor="let filter of ssp.filters; let i = index;"
          class="filters-list__item"
        >
          <div
            (click)="filter.isSelected = !filter.isSelected"
            *ngIf="ssp.filters[i].value.length > 0"
            [ngClass]="{'pointer': !!ssp.filters[i], 'opened': filter.isSelected}"
            class="filters-list__item-title"
          >
            {{ filter.label | transloco }}

            <ng-container *ngIf="ssp.filters[i]">
              <span class="filters-list__item-mode"> {{ (ssp.filters[i].mode ? 'include' : 'exclude') | lowercase }} </span>
              <div class="filters-list__item-count">{{ ssp.filters[i].value.length }}</div>
            </ng-container>
          </div>

          <div
            *ngIf="ssp.filters[i] && filter.isSelected"
            class="filters-list__item-value-list"
          >
            <div
              *ngFor="let value of ssp.filters[i].value"
              class="filters-list__item-value"
            >
              {{ value }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- End Filters  -->

    <!--  Geo revshare  -->
    <ng-container *ngIf="showTab === 2">
      <div class="revshare-list">
        <div
          *ngFor="let revshare of ssp.geoRevshare; let i = index;"
          class="revshare-list__item"
        >
          <div class="item-geo">{{ revshare.geo }}</div>
          <div class="item-revenue">{{ revshare.revenue }}</div>
        </div>
      </div>
    </ng-container>
    <!-- End Geo revshare  -->
  </adxad-sidebar-modal-body>
  <adxad-sidebar-modal-actions>
    <button
      (click)="openReportsGrid()"
      actionType="main-gray"
      adxadSidebarModalAction
      adxadTooltip="Statistics"
    >
      <span class="material-icons"> query_stats </span>
      Statistics
    </button>

    <button
      (click)="openEditForm()"
      actionType="main-blue"
      adxadSidebarModalAction
      type="button"
    >
      <span class="material-icons">edit</span>
      Edit
    </button>

    <button
      (click)="changeStatus(Status.active)"
      *ngIf="ssp.status === Status.stopped"
      actionType="main-green"
      adxadSidebarModalAction
    >
      <span class="material-icons">play_arrow</span>
      Activate
    </button>

    <button
      (click)="changeStatus(Status.stopped)"
      *ngIf="ssp.status === Status.active"
      actionType="main-red"
      adxadSidebarModalAction
    >
      <span class="material-icons">stop</span>
      Stop
    </button>
  </adxad-sidebar-modal-actions>
</adxad-sidebar-modal-container>
